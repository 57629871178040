import React from 'react';
import { Field } from 'formik'




// Home Address !!!
// Add to Package.json AND also in .env
const homeDir = "https://rhapso.tailorwise.com"


//Jade by Ashima
export const backAppId = "HS2DmqAOzz2IG4ol0QhGbpzSokEVDcPU7MDQp3xG"
export const backJSId = "yZcvHSEX8ASWtCIPAhxxbi7jBzjSCqnl0mEebdm5"
 


export const defaultStoreObjid = "FC237G0HJG"  // Main Store
export const defaultUserObjid = "hywLGNUCi6"  // ROY
export const defaultFacotyObjid = "pRqCzf88bX" // iOS sets factory whenever an order is sent from store to factory

export const awsBaseUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/tw-main/"
export const clientName = "Rhapso"

export const clientPhone = "+91-9620100003"   //
export const clientEmail = "info@tailorwise.com"
export const clientEmailFromId = "no-reply@tailorwise.com"  //TW no-reply-email or client validated email with us
export const clientCCEmail = ""

export const hasFactory = true
export const isSingleLocation = false
export const hasAgents = false
export const usesVendorViaFactory = true    // New Stage Factory_Recieved_For_Vendor added on 2-Aug-2021 - Also putting this as a flag in product as- usedVendorViaFactory & in business check usesVendorViaFactory

export const showOnlyClientFeature = false
export const useClientCid = true
export const showCustomProduction = false





// Specific Cofigurations

// Customer Info
export const config_cust_captureAddress = true
export const config_cust_captureProfession = true

export const clientWebsite = "https://tailorwise.com"
export const clientTermsAndConditionsUrl = "https://tailorwise.com/terms-conditions"

export const clientLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/Rhapso/rhapso-140x30.png"
export const clientHomeSquareLogo = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/Rhapso/rhapso-300x156.png"
export const clientMenuLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/Rhapso/rhapso-140x30.png"

export const currencySymbol = "₹"
export const currencyCode = "INR"
export const clientBGHome = ""
export const clientBGHero = ""




export const client_defaultCountry = 'IN (+91)'
export const client_defaultCountryOption = () => {
    return (
        <option value={client_defaultCountry} >{client_defaultCountry}</option>
    )
}

export const client_favCountryList = () => {

    // FIRST OPTION SHOULD BE SAME AS DEFAULT COUNTRY
    return (
        <>
            {client_defaultCountryOption()}
            <option value='US (+1)'  >US (+1)</option>
            <option value='CA (+1)'  >CA (+1)</option>
            <option value='AU (+61)'  >AU (+61)</option>
            <option value='GB (+44)'  >GB (+44)</option>
            <option value='AE (+971)'  >AE (+971)</option>
            <option value='ZW (+263)'  >ZW (+263)</option>
            <option value='ZA (+27)' >ZA (+27)</option>
        </>
    )

}






//// PRODUCTS OFFERED 
export const ProductType = {

    Tuxedo: "TUXEDO",
    Suit2piece: "SUIT 2 PIECE",
    Suit3piece: "SUIT 3 PIECE",
    LongCoat: "LONG COAT",
    WaistCoat: "WAIST COAT",
    Blazer: "BLAZER",
    Trouser: "TROUSER",
    Shorts: "SHORTS",
    Shirt: "SHIRT",
    Kurta: "KURTA",
    Bhandi: "BHANDI",
    Sherwani: "SHERWANI",
    IndoWestern: "INDO WESTERN",
    BomberJacket: "BOMBER JACKET",



    // Achkan: "ACHKAN",
    // BundyDhoti: "BUNDY DHOTI",
    // BowTie: "BOWTIE",
    // Tie: "TIE",
    // PocketSquare: "POCKET SQUARE",
    // Socks: "SOCKS",
    // Shoes: "SHOES",
    // Belt: "BELT",
    // Bag: "BAG",
    // Wallet: "WALLET",
    // Cufflinks: "CUFF LINKS",
    // Suspenders: "SUSPENDERS",
    Other: "OTHER",
    None: 'NONE',
    // Accessories: 'ACCESSORIES',




}


export const productType_allValues = () => {
    var products = []

    products.push(ProductType.Tuxedo)
    products.push(ProductType.Suit2piece)
    products.push(ProductType.Suit3piece)
    products.push(ProductType.LongCoat)
    products.push(ProductType.WaistCoat)
    products.push(ProductType.Blazer)
    products.push(ProductType.Trouser)
    products.push(ProductType.Shorts)
    products.push(ProductType.Shirt)
    products.push(ProductType.Kurta)
    products.push(ProductType.Bhandi)
    products.push(ProductType.Sherwani)
    products.push(ProductType.IndoWestern)
    products.push(ProductType.BomberJacket)
    products.push(ProductType.Other)

    products.sort(function (a, b) {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
    })

    return products
}


export const ProductType_options_NonFormik = (name, classNames, onChangeFunc, value, showNone) => {
    return (
        <select name={name} className={'select ' + classNames} value={value ?? ''} onChange={onChangeFunc}>

            { showNone ? <option>{ProductType.None}</option> : null }

            {productType_allValues().map((thisVal) => {
                return <option>{thisVal}</option>
            })}


        </select>
    )
}


export const ProductType_options = (name, classNames, onChangeFunc) => {
    return (
        <Field as='select' name={name} className={'select ' + classNames} onChange={onChangeFunc}>
            {productType_allValues().map((thisVal) => {
                return <option>{thisVal}</option>
            })}
        </Field>
    )
}


export const productType_isGroup = (type) => {

    return false
    // switch (type) {
    //     case ProductType.Suit2piece: case ProductType.Suit3piece: case ProductType.KurtaPyjama: case ProductType.Sherwani: case ProductType.IndoWestern: case ProductType.Tuxedoe: case ProductType.SafariSuit: return true
    //     default: return false
    // }
}


export const productType_groupProducts = (type) => {
    return []
    // switch (type) {
    //     case ProductType.Sherwani: return [ProductType.Sherwani, ProductType.Kurta, ProductType.Pyjama]
    //     case ProductType.Suit2piece: return [ProductType.Coat, ProductType.Trouser]
    //     case ProductType.Suit3piece: return [ProductType.Coat, ProductType.Trouser, ProductType.WaistCoat]
    //     case ProductType.KurtaPyjama: return [ProductType.Kurta, ProductType.Pyjama]
    //     case ProductType.IndoWestern: return [ProductType.IndoWestern, ProductType.Trouser]
    //     case ProductType.Tuxedoe: return [ProductType.Tuxedoe, ProductType.Trouser]
    //     case ProductType.SafariSuit: return [ProductType.SafariSuit, ProductType.Trouser]
    //     default: return []
    // }
}


export const StyleSelectionProductType_options_NonFormik = (name, classNames, onChangeFunc, value) => {
    return (
        <select name={name} className={'select has-background-grey-dark has-text-white ' + classNames} value={value ?? ''} onChange={onChangeFunc}>
            <option>{"All"}</option>
            {productType_allValues().map((thisVal) => {
                return <option>{thisVal}</option>
            })}

        </select>
    )
}





