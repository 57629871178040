import React, { useState } from 'react'
import {  Heading, Button } from 'react-bulma-components/dist'
import ProductsOfOrder from '../../containers/ProductsOfOrder'
import { Order } from '../../utilities/Classes'
import { OrderFilter } from '../../utilities/Filters'
import OrderCard from '../../components/Cards/OrderCard'
import { screen } from '../../utilities/enums'
import ColumnAux from '../../containers/ColumnAux'

import OrderFilterModal from './orderFilterModal'
import cogoToast from 'cogo-toast'

import CsvDownloader from 'react-csv-downloader';




const OrderReports = () => {

    const [orders, setOrders] = useState([])
    const [searching, setSearching] = useState(false)
    const [filter, setFilter] = useState(OrderFilter.init())
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [showFilter, setShowFilter] = useState(false)


    const closeModals = () => {
        setSelectedOrder(null)
        setShowFilter(false)
    }

    const selectOrderPressed = (ord) => [
        setSelectedOrder(ord)
    ]

    const showFilterSelected = () => {
        setShowFilter(true)
    }

    const updatefilter = (filter) => {
        setOrders([])
        setFilter(filter)
        console.log('UPDATED FILTER =')
        console.log(filter)
        setShowFilter(false)
        if (filter.active) {
            searchFororders(filter)
        }
    }

    const searchFororders = (of) => {
        console.log('Searching for orders with filter- ' + of.description())
        setSearching(true)
        Order.getAll(of, (succ, orders, msg) => {
            if (succ) {
                setOrders(orders)
            } else {
                cogoToast.error('Could not find Orders. An error occured.')
                // console.log(msg)
            }
            setSearching(false)
        })
    }

    const exportCSV = () => {

        return <div>
           <CsvDownloader
             filename="orderReport"
             extension=".csv"
             separator=";"
             wrapColumnChar=""
             columns={Order.csvColumns()}
             datas={
                orders.map((c) => {
                   return Order.copyFrom(c).csvData()
               })
             }
             text="Download CSV" />
         </div>  
     }


    const ordersView = () => {
        return (
            <div>
                <div className='is-ceneterd has-text-centered'>
                    <Button disabled={searching} loading={searching} className={filter.active ? ' is-primary  has-text-bold' : ' has-text-bold'} onClick={showFilterSelected}  > Conditions {filter.filterCount > 0 ? '(' + filter.filterCount + ' Active)' : null} </Button>
                </div>
                <br />
                <Heading size={6} className=" has-text-centered marginBottom6"> {filter.active ? filter.description() : 'No Conditions Selected'} </Heading>
                {filter.active ?
                  exportCSV()
                    : null }
                <br />
                {/* <Fade right cascade> */}
                {
                    orders.length > 0 ?

                        <ColumnAux size={8} isMiddle={true}>
                            {orders.map((ord, index) => {
                                return <OrderCard key={index} order={ord} selectOrder={selectOrderPressed} />
                            })}
                        </ColumnAux>

                        :
                        null
                }
                {/* </Fade> */}
                {((filter.active && orders.length === 0) && searching === false) ? <div > <br /> <br />  <Heading className=' type70 has-text-grey-light has-text-centered is-centered'> No such Orders </Heading> </div> : null}
            </div>
        )
    }


    const orderIsUpdated = () => {
        console.log('Order is Updated')
    }

    const orderHasMoved = () => {
        console.log('Order has Moved')
    }


    return (
        <div>
            {showFilter ? <OrderFilterModal filter={filter} filterSaved={updatefilter} closed={closeModals} /> : null}
            {selectedOrder ? <ProductsOfOrder customer={null} order={selectedOrder} fetchProducts={true} screen={screen.OrderReports} orderUpdated={orderIsUpdated} orderHasMoved={orderHasMoved} backPressed={closeModals} /> : ordersView()}
        </div>
    )


}

export default OrderReports