import React, { useState } from 'react'
import Customers from '../../General/Customers'
import { Tile, Columns, Heading, Button } from 'react-bulma-components/dist'
import CustomerCard from '../../components/Cards/CustomerCard'
import { Customer } from '../../utilities/Classes'
import { CustomerFilter } from '../../utilities/Filters'
import Client from '../../Departments/Store/client'
import { screen } from '../../utilities/enums'
import ClientFilterModal from './clientFilterModal'
import cogoToast from 'cogo-toast'
import CsvDownloader from 'react-csv-downloader';


const ClientReports = () => {

    const [customers, setCustomers] = useState([])
    const [searching, setSearching] = useState(false)
    const [custFilter, setCustFilter] = useState(CustomerFilter.init())
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [showFilter, setShowFilter] = useState(false)


    const closeModals = () => {
        setSelectedCustomer(null)
        setShowFilter(false)
    }

    const selectCustomerPressed = (cust) => [
        setSelectedCustomer(cust)
    ]

    const showFilterSelected = () => {
        setShowFilter(true)
    }

    const updateCustFilter = (filter) => {
        setCustomers([])
        setCustFilter(filter)
        console.log('UPDATED FILTER =')
        console.log(filter)
        setShowFilter(false)
        if (filter.active) {
            searchForCustomers(filter)
        }
    }

    const searchForCustomers = (cf) => {
            // console.log('Searching for Customers with filter- ' + cf.description())

            setSearching(true)
            Customer.getAll(cf, (succ, customers, msg) => {
                if (succ) {
                    setCustomers(customers)
                } else {
                    cogoToast.error('Could not find Customers. An error occured.')
                    // console.log(msg)
                }
                setSearching(false)
            })
    }


    const exportCSV = () => {
     return <div>
        <CsvDownloader
          filename="customerReport"
          extension=".csv"
          separator=";"
          wrapColumnChar=""
          columns={Customer.csvColumns()}
          datas={
            customers.map((c) => {
                return Customer.copyFrom(c).csvData()
            })
          }
          text="Download CSV" />
      </div>  
  }


    const customersView = () => {
        return (
            <div>
                <div className='is-ceneterd has-text-centered'>
                    <Button disabled={searching} loading={searching} className={ custFilter.active ?  ' is-primary  has-text-bold'  : ' has-text-bold' } onClick={ showFilterSelected }  > Conditions { custFilter.filterCount > 0 ?  '(' + custFilter.filterCount + ' Active)' : null } </Button>
                </div>
                <br />
                  <Heading size={6} className=" has-text-centered marginBottom6"> { custFilter.active ? custFilter.description() : 'No Conditions Selected' } </Heading>
                  {custFilter.active ?
                  
                  exportCSV()
                
                    : null }
                <br />
                <Tile kind="ancestor" className=' customerGrid' >
                    {/* <Fade right cascade> */}
                    {
                        customers.length > 0  ?

                        customers.map((cust, index) => {
                            return (
                                <Tile key={index} kind='parent' className=''>
                                    <Tile className='' >
                                        <CustomerCard key={index} customer={cust} selectCustomer={selectCustomerPressed} />
                                    </Tile>
                                </Tile>
                            )
                        })

                        : 
                        null
                    }
                    {/* </Fade> */}
                </Tile>
                {( (custFilter.active && customers.length === 0)  && searching === false) ?  <div > <br/> <br/>  <Heading className=' type70 has-text-grey-light has-text-centered is-centered'> No such Customers </Heading> </div>  : null}
            </div>
        )
    }




    return (
        <div>
            {showFilter ? <ClientFilterModal custFilter={custFilter}  filterSaved={updateCustFilter}  closed={closeModals} />  : null}
            {selectedCustomer ? <Client isEmbedded={true} screen={screen.ClientReports} customer={selectedCustomer}  backPressed={closeModals} /> : customersView()}
        </div>
    )


}

export default ClientReports